<template>
  <div class="ss-setting">
    <div class="ss-setting__header">
      <h2 class="header__title">
        {{ $t('schedule.setting.commonDataSetting') }}
      </h2>
    </div>
    <div class="ss-setting__body">
      <spinner v-if="isLoading" />
      <template v-else>
        <div
          v-for="file in files"
          :key="file.code"
          class="file"
        >
          <single-common-file :file-data="file" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import SingleCommonFile from './SingleCommonFile'
import { fetchDataBoundStatus } from '@/schedule/API/Project'
import { DATA_CATEGORY } from '@/schedule/utils/enum'
import { mapState } from 'vuex'

export default {
  name: 'CommonDataSetting',
  components: {
    SingleCommonFile
  },
  data () {
    return {
      isLoading: true,
      files: []
    }
  },
  computed: {
    ...mapState('scheduleSetting', ['scheduleProjectId'])
  },
  mounted () {
    this.fetchFiles()
  },
  methods: {
    fetchFiles () {
      this.isLoading = true
      fetchDataBoundStatus(this.scheduleProjectId)
        .then(files => {
          files.forEach(file => {
            if (file.category === DATA_CATEGORY.RAW_DATA) this.files.push(file)
          })
        })
        .catch(() => {})
        .finally(() => this.isLoading = false)
    }
  }
}
</script>
